// Font
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

// Variables
$primary: #198754;

@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// AOS
@import "~aos/src/sass/aos";

// Hero
#hero .animate-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@include media-breakpoint-down(lg) {
    #hero .animate-image {
        -webkit-animation: none;
        animation: none;
    }
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

.min-h-screen {
    min-height: 100vh;
}

.h-screen {
    height: 100vh;
}

.h-1\/2-screen {
    height: 50vh;
}

.min-h-1\/2-screen {
    min-height: 50vh;
}